<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="orderprofits" ref="dt" v-model:selection="selectedRow" :metaKeySelection="false"
                    v-model:filters="filters1" filterDisplay="menu" dataKey="id" :scrollable="true"
                    :tableStyle="styleHeight" :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading"
                    :paginator="false" :lazy="false">
                    <template #header>
                        <div class="grid">
                            <div class="col-8">
                                <div class="text-left">
                                    <Calendar placeholder="开始时间" v-model="startTime" dateFormat="yy-mm-dd" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Calendar placeholder="结束时间" v-model="endTime" dateFormat="yy-mm-dd" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="text-right">
                                    <Button type="button" label="查询" class="mb-2"
                                        @click="customFilter();" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-external-link" label="导出" @click="exportData()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                        class="p-button-outlined mb-2" @click="clearFilter1()" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="outtime" style="min-width:130px" :showFilterOperator="false" header="出库日期" frozen
                        :filterMatchModeOptions="timeMatchModes" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="orderid" :showFilterOperator="false" style="min-width:140px" header="OrderID"
                        :sortable="true" :filterMatchModeOptions="matchModes">
                        <template #filter="{filterModel}">
                            <Calendar v-model="filterModel.value" class="p-column-filter" dateFormat="yy-mm-dd" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="buyercode" :showFilterOperator="false" style="min-width:130px" header="BuyerCode"
                        :sortable="true" :filterMatchModeOptions="matchModes">
                        <template #filter="{filterModel}">
                            <Calendar v-model="filterModel.value" class="p-column-filter" dateFormat="yy-mm-dd" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="creater" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                        style="min-width:130px" header="业务员" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="name" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                        style="min-width:140px" header="Name" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="productname" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                        style="min-width:180px" header="ProductName" :sortable="true">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="mpn" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                        style="min-width:150px" header="MPN" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="itemcode" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                        style="min-width:150px" header="ItemCode" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="salesquantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                        style="min-width:160px" header="SalesQuantity" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="fulfillmentperunit" :showFilterOperator="false"
                        :filterMatchModeOptions="numMatchModes" style="min-width:160px" header="FulfillmentPerUnit"
                        :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="total" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                        style="min-width:150px" header="Total" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="depositamount" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                        style="min-width:150px" header="定金金额" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="netamount" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                        style="min-width:150px" header="入账金额" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="outprice" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                        style="min-width:150px" header="出库成本" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="productprofit" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                        style="min-width:150px" header="产品利润" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="ordertype" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                        style="min-width:130px" header="OrderType" :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '735';
            const allRecords = ref(0);
            const allRecords2 = ref(0);
            return {
                confirm,
                currentMenuId,
                allRecords,
                allRecords2
            };
        },
        data() {
            return {
                matchModes: [],
                timeMatchModes: [],
                numMatchModes: [],
                butLoading: false,
                displayMaximizable: false,
                displayPosition: false,
                position: 'bottomleft',
                filters1: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                first: 0,
                listTitle: '订单出库利润统计',
                selectedKey: {
                    735: true
                },
                expandedKey: null,
                startTime: '',
                endTime: '',
                orderprofits: [],
                loading: false,
                nodes: [],
                items: [{
                    label: '统计操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportData();
                        }
                    }, ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        created() {
            MessageTip.initMsg();
            this.initFilters1();
            this.loadMenuTree();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
        },
        methods: {
            initFilters1() {
                this.timeMatchModes = [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ];
                this.numMatchModes = [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ];
                this.matchModes = [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ];
                this.filters1 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'outtime': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.DATE_BEFORE
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.DATE_AFTER
                        }]
                    },
                    'orderid': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'buyercode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'creater': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'name': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'productname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'itemcode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'salesquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'fulfillmentperunit': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'total': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'depositamount': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'netamount': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'outprice': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'productprofit': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'ordertype': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                }
            },
            clearFilter1() {
                this.startTime = '';
                this.endTime = '';
                this.initFilters1();
            },
            loadMenuTree() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '731',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usertreemenulist(where:" + JSON.stringify(listwhere) +
                    "){key label data nodetype icon leaf children {key label data nodetype parentid parenturl icon leaf} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.nodes = jsonData.data.usertreemenulist;
                        if (this.$route.query.nodeid && this.$route.query.nodeid != '' && this.nodes) {
                            this.selectedKey = {};
                            this.selectedKey[this.$route.query.nodeid] = true;
                            this.expandedKey = {};
                            this.expandedKey[this.currentMenuId] = true;
                            var isFind = false;
                            for (var i = 0; i < this.nodes.length; i++) {
                                for (var j = 0; j < this.nodes[i].children.length; j++) {
                                    if (this.nodes[i].children[j].key == this.$route.query.nodeid) {
                                        isFind = true;
                                        this.queryPlanObj = {
                                            name: this.nodes[i].children[j].label,
                                            id: this.nodes[i].children[j].key,
                                            parentid: this.currentMenuId,
                                            queryconfig: this.nodes[i].children[j].data,
                                        };
                                        if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig !=
                                            '') {
                                            this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                        }
                                        this.listTitle = this.nodes[i].children[j].label;
                                        break;
                                    }
                                }
                                if (isFind) {
                                    break;
                                }
                            }
                        }
                    }
                });
            },
            onNodeSelect(node) {
                console.log(node);
                setTimeout(() => {
                    var currentKey = '';
                    if (this.selectedKey) {
                        for (var key in this.selectedKey) {
                            currentKey = key;
                            break;
                        }
                    }
                    if (node.nodetype == '1') {
                        this.queryPlanObj = {
                            name: '',
                            id: '-1',
                            parentid: this.currentMenuId,
                            queryconfig: '',
                        };
                        console.log('node.key:' + node.key);
                        console.log('selectedKey:');
                        console.log(this.selectedKey);
                        console.log('currentKey:' + currentKey);
                        if (node.key != this.currentMenuId) {
                            window.location.href = node.data;
                        } else {
                            this.clearFilter1();
                            this.clearFilter2();
                            this.loadData(1);
                            this.listTitle = "订单出库利润统计";
                        }
                    }
                    if (node.nodetype == '2') {
                        if (this.currentMenuId != node.parentid) {
                            window.location.href = node.parenturl + "?nodeid=" + node.key;
                        } else {
                            this.queryPlanObj = {
                                name: node.label,
                                id: node.key,
                                parentid: this.currentMenuId,
                                queryconfig: node.data,
                            };
                            console.log('expandedKey:');
                            console.log(this.expandedKey);
                            if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig != '') {
                                console.log('queryconfig:');
                                console.log(this.queryPlanObj.queryconfig);
                                this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                this.loadData(1);
                                this.listTitle = node.label;
                            }
                        }
                    }
                }, 20);
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.loadData(page + 1);
            },
            loadData(v_page) {
                if (this.startTime == '' || this.endTime == '') {
                    MessageTip.warnmsg('请选择统计周期');
                    return;
                }
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: -1,
                    conditions: [{
                        name: 'starttime',
                        value: this.startTime,
                        operation: '='
                    }, {
                        name: 'endtime',
                        value: this.endTime,
                        operation: '='
                    }]
                };
                for (var key in this.filters1) {
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{outstockstatlist(where:" + JSON.stringify(listwhere) +
                    "){id outtime orderid buyercode creater name productname mpn itemcode salesquantity fulfillmentperunit total depositamount netamount outprice productprofit ordertype }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.orderprofits = jsonData.data.outstockstatlist;
                    }
                });
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            exportData() {
                this.$refs.dt.exportCSV();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            'AppFooter': AppFooter,
        }
    }
</script>